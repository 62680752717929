const tabs = document.querySelectorAll(".tx-dftabs-tabMenuEntry");
if (tabs.length != 0) {
    for (const tab of tabs) { 
        tab.addEventListener("click", function() {
            for (const item of tabs) {
                item.classList.remove("tx-dftabs-tabMenuEntrySelected");
            } 
    
            tab.classList.add("tx-dftabs-tabMenuEntrySelected");
        })
    }
}