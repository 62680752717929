import '../../../../extensions/imp_base/Resources/Public/test.js';
import '../../../../extensions/imp_base/Resources/Public/test2021.js';

window.addEventListener('load', e => {
    const modules = import.meta.glob([
        './utils/*.js',
        './Content/*.js',
        './components/*.js',
    ]);

    for (const path in modules) {
        new Promise((_res, _rej) => modules[path]());
    }
});
