import ElementPosition from '../utils/ElementPosition';

(() => {
    const nodes = document.querySelectorAll('.jump-anchors');

    if (nodes.length === 0) {
        return;
    }

    for (const node of nodes) {
        const anchors = node.querySelectorAll('a');

        for (const anchor of anchors) {
            anchor.onclick = e => {
                e.preventDefault();

                const { scrollid } = e.target.dataset;
                const element = document.querySelector(`#${scrollid}`);
                const top = new ElementPosition(element).top - document.querySelector('header.main-header').clientHeight;

                window.scroll(0, top, { behavior: 'smooth' });
            };
        }
    }
})();
